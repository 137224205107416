import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styles";

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            summary
            presentation
          }
        }
      }
    }
  `);

  const { author } = data.site.siteMetadata;
  return (
    <S.Bio>
      <h1>
        {author.presentation}{" "}
        <span role="img" aria-label="waving">
          👋
        </span>
      </h1>
      <S.BioSubHeading>{author.summary}</S.BioSubHeading>
    </S.Bio>
  );
};

export default Bio;
